import { useState } from 'react';
import { Auth } from 'aws-amplify';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Container';
import './SendVerificationCode.css';

function SendVerificationCode(props) {

    const [username, setUsername] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleRequestReset = async () => {
        try {
            setError('')
            setIsLoading(true)
            console.log('username: ' + username)
            const d = await Auth.forgotPassword(username);
            console.log('data: ' + d)
            setIsLoading(false)
            props.onResetPassword()
        } catch (error) {
            console.error('error sending verification code: ' + error);
            setError('Erro ao enviar código de verificação. Teste novamente em alguns minutos')
            setIsLoading(false)
        }
    };

    const handleAlreadyContains = async () => {
        props.onResetPassword()
    }

    const handleGoBackLogin = async () => {
        props.onBack()
    }
    return (
        <div>
            <div className='loginDivTitle'>
                <span className='loginTitle'>Esqueceu sua senha?</span>
            </div>
            <div className='instructions'>
                Confirme seu e-mail para receber as instruções e o código de verificação para criar uma nova senha.
            </div>
            <Form className='loginForm'>
                <div>
                    <Form.Group controlId="formBasicText">
                        <Form.Label>Email</Form.Label>
                        <Form.Control className='inputText' type="email" placeholder="Insira seu email"
                            onChange={evt => setUsername(evt.target.value)}
                        />
                    </Form.Group>
                </div>
                <div className='alreadyContainsCode'>
                    <Form.Label className='alreadyContainsCode'
                        onClick={handleAlreadyContains}>
                        Já recebi as instruções
                    </Form.Label>
                </div>
                {error &&
                    <Alert className="alertLogin">
                        {error}
                    </Alert>}

                <Button className='enterButton' type="button"
                    onClick={handleRequestReset}>
                    {isLoading ? (
                        <>
                            <Spinner animation="border" size="sm" />
                            {'Enviando...'}
                        </>
                    ) : (
                        'Enviar'
                    )}
                </Button>
                <div className='goBackToLogin'>
                    <Form.Label className='goBackToLogin'
                        onClick={handleGoBackLogin}>
                        Voltar para o Login
                    </Form.Label>
                </div>
            </Form>
        </div>

    )
}

export default SendVerificationCode;