import { useNavigate } from 'react-router-dom';
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button';
import "./HomePage.css";

function HomePage(props) {
    const navigate = useNavigate()

    const goToLogin = async () => {
        try {
            navigate('/login')
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className='video-background'>
            <video autoPlay muted loop >
                <source src="/video/file.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className='home-header'>
                <div className='login-button-div'>
                    <Button className='login-button' onClick={goToLogin} >
                        Entrar
                    </Button>
                </div>
            </div>
            <div className='main-content'>
                <div className='center-content'>
                    <Image src="/img/ds1.webp" />
                    <p>
                        A solução completa para a proteção de pessoas,<br />
                        produtos e veículos na sua área de carga e descarga.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HomePage