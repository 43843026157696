import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

import DockInfo from "./dockInfo";
import CustomMultiSelect from './CustomMultiSelect/CustomMultiSelect';
import LateralMenu from "./FlexLateralMenu/LateralMenu";

function DashBoard(props) {
    const navigate = useNavigate()
    const [dashboardData, setDashboardData] = useState(null);
    const ws_url = 'wss://nbsh0jxr5h.execute-api.us-east-2.amazonaws.com/development'
    const predefinedStatuses = ['free', 'taken', 'loading', 'finished'];
    const statusMap = {
        'free': 'Livre',
        'taken': 'Ocupado',
        'loading': 'Carregando',
        'finished': 'Carregado'
    }
    const [filterOptions, setFilterOptions] = useState([]);
    const [selectedSectorFilter, setSelectedSectorFilter] = useState([]);
    const onSelectSectorFilter = (selectedSectors) => {
        setSelectedSectorFilter(selectedSectors);
    };
    const onRemoveSectorFilter = (selectedSectors) => {
        setSelectedSectorFilter(selectedSectors);
    };

    const onCloseFilters = () => {
        setFilter()
    }
    const setFilter = async () => {
        try {
            const filter = {
                sectors: [],
            }
            for (const sector of selectedSectorFilter) {
                filter.sectors.push(sector.id)
            }
            const currentSession = await Auth.currentSession();
            const accessToken = currentSession.getAccessToken().getJwtToken();
            const response = await fetch('https://api.docksystem.com.br/dashboard/filters', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(filter)
            });

            if (!response.ok) {
                throw new Error('Request failed');
            }
            const jsonData = await response.json();
            setDashboardData(jsonData);
        } catch (error) {
            console.log('Request Error: ' + error)
            if (error.response && error.response.status === 401) {
                console.log("fetchdata token expired: " + error)
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        async function fetchUser() {
            try {
                await Auth.currentAuthenticatedUser();
            } catch (error) {
                navigate('/login')
                console.log('[dashboard] user is not logged: ' + error);
            }
        }
        fetchUser()
    });
    useEffect(() => {
        fetchDashboard()
    }, []);

    const fetchDashboard = async () => {
        try {
            const currentSession = await Auth.currentSession();
            const accessToken = currentSession.getAccessToken().getJwtToken();
            console.log(accessToken)
            const response = await fetch('https://api.docksystem.com.br/dashboard', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Request failed');
            }
            const jsonData = await response.json();
            const sectorOpt = []
            for (const sector of jsonData.sectors) {
                sectorOpt.push({ id: sector.id, label: sector.name })
            }
            setFilterOptions(sectorOpt)
            const userSectors = []
            for (const sector of jsonData.user_sectors) {
                userSectors.push({ id: sector.id, label: sector.name })
            }
            setSelectedSectorFilter(userSectors)
            setDashboardData(jsonData);
        } catch (error) {
            console.log('Request Error: ' + error)
            if (error.response && error.response.status === 401) {
                console.log("fetchdata token expired: " + error)
                navigate('/login')
            }
        }
    }
    useEffect(() => {
        let wsClient;
        const setupWebSocket = async () => {
            try {
                const currentSession = await Auth.currentSession();
                const accessToken = currentSession.getAccessToken().getJwtToken();

                wsClient = new WebSocket(ws_url + `?token=${accessToken}`);
                wsClient.onmessage = (evt) => {
                    try {
                        const jsonObj = JSON.parse(evt.data)
                        if (!jsonObj.hasOwnProperty('type')) {
                            const dockas = JSON.parse(evt.data);
                            setDashboardData(dockas);
                        }
                    } catch (e) {
                        console.log('error receiving data from ws ', e);
                    }
                };
                wsClient.onerror = (error) => {
                    console.log('WebSocket Error: ', error);
                };
                wsClient.onclose = (event) => {
                    console.log('WebSocket Closed:', event);
                    setTimeout(() => {
                        setupWebSocket();
                    }, 5000); // Reconnect after 5 seconds
                };

                // Periodically send a ping message to keep the connection active
                setInterval(() => {
                    if (wsClient.readyState === WebSocket.OPEN) {
                        wsClient.send(JSON.stringify({ type: 'ping' }));
                    }
                }, 500000); // Send a ping every 5 minutes (adjust the interval as needed)

            } catch (error) {
                console.log('Error setting up WebSocket: ', error);
            }
        };
        setupWebSocket();

        return () => {
            if (wsClient && wsClient.readyState === WebSocket.OPEN) {
                console.log('Closing WebSocket');
                wsClient.close();
            }
        };
    }, []);

    const mapStrings = (inputString, stringMap) => {
        if (stringMap[inputString]) {
            return stringMap[inputString];
        } else {
            return inputString;
        }
    };

    const renderItems = (status) => {
        if (!Array.isArray(dashboardData.docks) || dashboardData.docks.length === 0) {
            console.error("Items is not an initialized array.");
            return null;
        }

        const filteredItems = dashboardData.docks.filter(dock => dock.operation === status);
        if (filteredItems.length === 0) {
            return null
        }

        return filteredItems.map((dock, index) => (
            <DockInfo
                key={index}
                data={dock}
            />
        ));
    };

    return (
        <section className="flex h-screen">
            <LateralMenu />
            <div className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
                <div className="p-4">
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="text-2xl font-semibold">Dashboard</h1>
                        <div className="flex items-center">
                            <CustomMultiSelect
                                options={filterOptions}
                                selectedValues={selectedSectorFilter}
                                onSelect={onSelectSectorFilter}
                                onRemove={onRemoveSectorFilter}
                                onCloseFilters={onCloseFilters}
                            />
                        </div>
                    </div>
                    <div className="flex gap-4">
                        {predefinedStatuses.map((status) => (
                            <div key={status} className="flex-1">
                                <div className="mb-2 p-4 bg-white shadow-md border border-gray-300 rounded-md">
                                    <h2 className="text-lg font-semibold">{mapStrings(status, statusMap)}</h2>
                                </div>
                                <div>{dashboardData && dashboardData.docks && renderItems(status, dashboardData.docks)}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>

    )
}

export default DashBoard