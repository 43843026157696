
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@aws-amplify/ui-react/styles.css'

import { Route, Routes } from 'react-router-dom'

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports'

import HomePage from './components/home/HomePage';
import DashBoard from './components/dashboard/DashBoard';
import LoginPage from './components/auth/LoginPage';

import "./App.css";
import React from 'react';
import Modal from 'react-modal';

// Set the root element for the application
Modal.setAppElement('#root');

Amplify.configure(awsExports);
function App() {
  return (
    <div>
      <Routes>
        <Route path='*' element={<HomePage key="homepage" />} />
        <Route path='/' exact={true} element={<HomePage key="homepage1" />} />
        <Route path='/login' element={<LoginPage key="loginpage" />} />
        <Route path='/dashboard' element={<DashBoard key="dashboard" />} />
      </Routes>
    </div>
  )
};

export default App;
