import React, { useState, useEffect } from 'react';
import Modal from 'react-modal'
const DockCard = ({ isOpen, onRequestClose, cardName, sector, status, startDate }) => {
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        let intervalId;

        if (isOpen) {
            const initialTimeDifference = Math.floor((Date.now() - startDate) / 1000);
            setTimer(initialTimeDifference);
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [isOpen, startDate]);

    const formatTimer = () => {
        const days = Math.floor(timer / (24 * 3600));
        const hours = Math.floor((timer % (24 * 3600)) / 3600);
        const minutes = Math.floor((timer % 3600) / 60);
        const seconds = timer % 60;

        const formatPlural = (value, unit) => `${value} ${unit}${value !== 1 ? 's' : ''}`;

        const formattedTime = [
            days > 0 && formatPlural(days, 'dia'),
            hours > 0 && formatPlural(hours, 'hora'),
            minutes > 0 && formatPlural(minutes, 'minuto'),
            seconds > 0 && formatPlural(seconds, 'segundo'),
        ]
            .filter(Boolean)
            .join(' ');

        return formattedTime || '0 segundos';
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 max-w-lg w-full rounded-lg shadow-md"
        >

            <h2 className="text-2xl font-bold mb-4">{cardName}</h2>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'left' }}>
                    <strong style={{ minWidth: '180px', textAlign: 'left' }}>Setor:</strong>
                    <span>{sector}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'left' }}>
                    <strong style={{ minWidth: '180px', textAlign: 'left' }}>Operação:</strong>
                    <span>{status}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'left' }}>
                    <strong style={{ minWidth: '180px', textAlign: 'left' }}>Tempo nesta operação:</strong>
                    <span>{formatTimer()}</span>
                </div>
            </div>

            <div className="mt-4 flex justify-end">
                <button className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 focus:outline-none" onClick={onRequestClose}>
                    Fechar
                </button>
            </div>
        </Modal>

    );
};
export default DockCard;