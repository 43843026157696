import { useState } from 'react';
import { Auth } from 'aws-amplify';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { IsStrongPassword } from '../../../utils/utils'

import "./FirstLogin.css";

function FirstLogin(props) {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    async function changePassword(newPassword) {
        try {
            console.log(" new: " + newPassword)
            const data = await Auth.completeNewPassword(props.checkUser, newPassword, null, null);
            console.log(data);
        } catch (err) {
            console.log('erro aqui: ' + err);
        }
    }

    const handleChangePassword = async () => {
        try {
            if (newPassword !== confirmNewPassword) {
                setIsLoading(false)
                setError('Confirmação de senha inválida')
                return
            }
            if (!IsStrongPassword(newPassword)) {
                setError('Senha não atende os requisitos mínimos')
                setIsLoading(false)
                return
            }
            changePassword(newPassword);
            console.log('Senha alterada com sucesso! ');
            setIsLoading(false)
            props.onLogin()
        } catch (err) {
            setIsLoading(false)
            setError('Erro ao trocar a senha, tente novamente')
            console.log('erro ao tentar trocar a senha: ', err)
        }
    }

    return (
        <div>
            <div className='loginDivTitle'>
                <span className='loginTitle'>Alteração de senha necessária</span>
            </div>
            <Form className='loginForm'>
                <div className='formFields'>
                    <Form.Group controlId="formBasicPassword">
                        <div>
                            <Form.Label>Nova senha</Form.Label>
                            <Form.Control className='inputText' type="password" minLength="8" placeholder="Defina sua nova senha de acesso"
                                onChange={evt => setNewPassword(evt.target.value)}
                            />
                        </div>
                        <div>
                            <Form.Label>Confirme sua nova senha</Form.Label>
                            <Form.Control className='inputText' type="password" minLength="8" placeholder="Confirme sua nova senha de acesso"
                                onChange={evt => setConfirmNewPassword(evt.target.value)}
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className='passwordRequirements'>
                    Requisitos de definição de senha:
                </div>
                <Alert className="passwordRequirementContext">
                    <p>
                        Mínimo de 8 caracteres
                        <br></br>
                        Pelo menos 1 número
                        <br></br>
                        Pelo menos 1 letra maiúscula
                        <br></br>
                        Pelo menos 1 letra minúscula
                    </p>
                </Alert>

                {error &&
                    <Alert className="alertLogin">
                        {error}
                    </Alert>}
                <Button className='enterButton' type="button"
                    onClick={handleChangePassword}>
                    {isLoading ? (
                        <>
                            <Spinner animation="border" size="sm" />
                            {'Verificando...'}
                        </>
                    ) : (
                        'Atualizar senha'
                    )}
                </Button>
            </Form>
        </div>
    )
}

export default FirstLogin;