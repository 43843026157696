import React from "react"
import { useState } from "react"
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { HiMenuAlt3 } from "react-icons/hi"
import { RxDashboard } from "react-icons/rx"
import { TbArticleFilledFilled } from "react-icons/tb"
import { TbLogout2 } from "react-icons/tb"
import { Link } from "react-router-dom"
import { MdOutlineSupportAgent } from "react-icons/md";

const LateralMenu = () => {
    const navigate = useNavigate()
    const menus = [
        { name: "Dashboard", link: '/dashboard', icon: RxDashboard, enable: true },
        { name: "Relatórios", link: '/', icon: TbArticleFilledFilled, enable: false },
        { name: "Suporte", link: '/', icon: MdOutlineSupportAgent, enable: false },
    ];
    const [open, setOpen] = useState(true);
    const [selectedMenu, setSelectedMenu] = useState(0);

    const handleMenuClick = (index) => {
        setSelectedMenu(index);
    };

    const handleLogout = async () => {
        try {
            await Auth.signOut()
            navigate('/')
        } catch (err) { console.log(err) }
    }
    return (
        <div className={`bg-[#0e0e0e] min-h-screen ${open ? 'w-72' : 'w-16'} duration-500 text-gray-100 px-3.5 flex flex-col h-screen z-10`}>
            <div className=" py-3 flex justify-end ">
                <HiMenuAlt3
                    size={26}
                    className="cursor-pointer"
                    onClick={() => setOpen(!open)}
                />
            </div>
            <div className="mt-4 flex flex-col gap-4 relative flex-grow ">
                {
                    menus?.map((menu, i) => (
                        <Link
                            to={menu?.link}
                            key={i}
                            className={`group flex items-center text-base gap-3.5 font-medium p-2 rounded-md 
                            ${selectedMenu === i ? 'bg-[#36AFAF]' : 'hover:bg-gray-800'} 
                            ${!menu.enable ? 'cursor-not-allowed opacity-50' : ''}`}
                            onClick={(e) => menu.enable ? handleMenuClick(i) : e.preventDefault()}>
                            <div>{React.createElement(menu?.icon, { size: "20" })}</div>
                            <h2
                                style={{
                                    transitionDelay: `${i + 3}00ms`
                                }}
                                className={`whitespace-pre duration-500 
                                        ${!open && "opacity-0 translate-x-28 "}`}
                            >
                                {menu?.name}
                            </h2>
                            <h2
                                className={`${open && 'hidden'}
                                    absolute left-48 bg-white font-semibold whitespace-pre 
                                    text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:-py-1
                                    group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>
                                {menu?.name}
                            </h2>
                        </Link>
                    ))
                }
            </div>
            <div className="mb-4 mt-4 flex flex-col gap-4 relative">
                <Link
                    key='logout-button'
                    className={`group flex items-center text-base gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`}
                    onClick={() => handleLogout()}
                >
                    <div>{React.createElement(TbLogout2, { size: "20" })}</div>
                    <h2
                        style={{
                            transitionDelay: `600ms`
                        }}
                        className={`whitespace-pre duration-500 
                                        ${!open && "opacity-0 translate-x-28 overflow-hidden"}`}
                    >
                        Sair
                    </h2>
                    <h2
                        className={`${open && 'hidden'}
                            absolute left-48 bg-white font-semibold whitespace-pre 
                            text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:-py-1
                            group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>
                        Sair
                    </h2>
                </Link>
            </div>
        </div>
    )
}

export default LateralMenu;