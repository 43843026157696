
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

import "./Login.css";

function Login(props) {
    const navigate = useNavigate()
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async () => {
        try {
            setError(false)
            setIsLoading(true)
            const user = await Auth.signIn(username, password);
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                setIsLoading(false)
                props.setCheckUser(user)
                props.onFirstLogin()
                return
            }
            setIsLoading(false)
            navigate('/dashboard')
        } catch (err) {
            setIsLoading(false)
            setError('Email ou senha inválido')
            console.log('erro ao tentar logar: ', err)
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const handleForgotPassword = async () => {
        props.onForgotPassword()
    }
    return (
        <div>
            <div className='loginDivTitle'>
                <span className='loginTitle'>Entrar</span>
            </div>
            <Form className='loginForm'>
                <div>
                    <Form.Group controlId="formBasicText">
                        <Form.Label>Email</Form.Label>
                        <Form.Control className="inputText" autoComplete='username' type="email" placeholder="Insira seu email"
                            onChange={evt => setUserName(evt.target.value)} />
                    </Form.Group>
                </div>
                <div>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control className='inputText' autoComplete='current-password' type="password" minLength="8" placeholder="Insira sua senha"
                            onChange={evt => setPassword(evt.target.value)}
                            onKeyPress={handleKeyPress} />
                    </Form.Group>
                </div>
                <div className='forgotPassword'>
                    <Form.Label className='forgotPassword'
                        onClick={handleForgotPassword}>
                        Esqueceu sua senha?
                    </Form.Label>
                </div>
                {error &&
                    <Alert className="alertLogin">
                        {error}
                    </Alert>}
                <Button className='enterButton' type="button"
                    onClick={handleLogin}>
                    {isLoading ? (
                        <>
                            <Spinner animation="border" size="sm" />
                            {' Verificando...'}
                        </>
                    ) : (
                        'Entrar'
                    )}
                </Button>

            </Form>
        </div>
    )
}

export default Login;