import { useState } from 'react';
import Image from 'react-bootstrap/Image'
import "./dockInfo.css";
import DockCard from './DockCard';
function DockInfo(props) {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const getLastUpdateAt = () => {
        const dateObject = new Date(props.data.last_status_update);
        const unixTimestamp = Math.floor(dateObject.getTime());
        return unixTimestamp;
    }

    const getStatus = (sts) => {
        switch (sts) {
            case "free":
                return "Livre"
            case "taken":
                return "Ocupado"
            case "loading":
                return "Carregando"
            case "finished":
                return "Carregado"
            default:
                return "Desconhecido"
        }
    }

    return (
        <div>
            <div className="card" onClick={handleOpenModal}>
                <div>
                    <Image className="dock-icon" src="/img/dsicon.png" />
                </div>
                <div className='card-info'>
                    {props.data.name}
                </div>
            </div>
            {showModal && (
                <DockCard
                    isOpen={showModal}
                    onRequestClose={handleCloseModal}
                    cardName={props.data.name}
                    sector={props.data.sector_name}
                    status={getStatus(props.data.operation)}
                    startDate={getLastUpdateAt()}
                />
            )}
        </div>
    )
}

export default DockInfo