import { useState } from 'react';

import { Auth } from 'aws-amplify';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { IsStrongPassword } from '../../../utils/utils'

import './ResetPassword.css';

function ResetPassword(props) {
    const [username, setUserName] = useState('')
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleResetPassword = async () => {
        try {
            await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChangePassword = async () => {
        try {
            if (newPassword !== confirmNewPassword) {
                setIsLoading(false)
                setError('Confirmação de senha inválida')
                return
            }
            if (!IsStrongPassword(newPassword)) {
                setError('Senha não atende os requisitos mínimos')
                setIsLoading(false)
                return
            }
            handleResetPassword(newPassword);
            console.log('Senha alterada com sucesso!');
            setIsLoading(false)
            props.onLogin()
        } catch (err) {
            setError('Erro ao trocar a senha')
            console.log('erro ao tentar trocar a senha: ', err)
            setIsLoading(false)
        }
    }
    const handleGoBackLogin = async () => {
        props.onBack()
    }
    return (
        <div>
            <div className='loginDivTitle'>
                <span className='loginTitle'>Alteração de senha</span>
            </div>
            <Form className='loginForm'>
                <div>
                    <div>
                        <Form.Group controlId="resetEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control className="inputText" autoComplete='username' type="email" placeholder="Insira seu email"
                                onChange={evt => setUserName(evt.target.value)} />
                        </Form.Group>
                    </div>
                    <Form.Group controlId="verificationCode">
                        <Form.Label>Código de verificação</Form.Label>
                        <Form.Control className='inputText' placeholder="Insira o código recebido por email"
                            onChange={evt => setVerificationCode(evt.target.value)}
                        />
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Senha</Form.Label>
                        <Form.Control id='newPassword' className='inputText' autoComplete='new-password' type="password" minLength="8" placeholder="Defina sua nova senha de acesso"
                            onChange={evt => setNewPassword(evt.target.value)}
                        />
                        <Form.Label>Confirme sua senha</Form.Label>
                        <Form.Control id='confirmNewPassword' className='inputText' autoComplete='new-password' type="password" minLength="8" placeholder="Confirme sua nova senha de acesso"
                            onChange={evt => setConfirmNewPassword(evt.target.value)}
                        />
                    </Form.Group>
                </div>
                <div className='passwordRequirements'>
                    Requisitos de definição de senha:
                </div>
                <Alert className="passwordRequirementContext">
                    <p>
                        Mínimo de 8 caracteres
                        <br></br>
                        Pelo menos 1 número
                        <br></br>
                        Pelo mneos 1 letra maiúscula
                        <br></br>
                        Pelo menos 1 letra minúscula
                    </p>
                </Alert>

                {error &&
                    <Alert className="alertLogin">
                        {error}
                    </Alert>}
                <Button className='enterButton' type="button"
                    onClick={handleChangePassword}>
                    {isLoading ? (
                        <>
                            <Spinner animation="border" size="sm" />
                            {' Verificando...'}
                        </>
                    ) : (
                        'Atualizar senha'
                    )}
                </Button>
                <div className='goBackLogin'>
                    <Form.Label className='goBackLogin'
                        onClick={handleGoBackLogin}>
                        Voltar para o Login
                    </Form.Label>
                </div>
            </Form>
        </div>
    )
}

export default ResetPassword;