import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Image from 'react-bootstrap/Image'
import Login from './Login/Login';
import FirstLogin from './Login/FirstLogin';
import SendVerificationCode from './ForgotPassword/SendVerificationCode';
import ResetPassword from './ForgotPassword/ResetPassword';
import "./LoginPage.css";

function LoginPage(props) {
    const [currentForm, setCurrentForm] = useState(1);
    const navigate = useNavigate()
    const [checkUser, setCheckUser] = useState(null)

    useEffect(() => {
        async function fetchUser() {
            try {
                await Auth.currentAuthenticatedUser();
                navigate('/dashboard')
            } catch (error) {

            }
        }
        fetchUser()
    });

    const goToLogin = () => {
        setCurrentForm(1);
    };
    const goToFirstLogin = () => {
        setCurrentForm(2);
    };
    const goToForgotPassword = () => {
        setCurrentForm(3);
    };
    const goToResetPassword = () => {
        setCurrentForm(4);
    };

    return (
        <div className="App">
            <div className="appLeft">
                <div className="loginDiv">
                    <div className='loginComponents'>
                        {currentForm === 1 && <Login onFirstLogin={goToFirstLogin} onForgotPassword={goToForgotPassword} setCheckUser={setCheckUser} />}
                        {currentForm === 2 && <FirstLogin onLogin={goToLogin} checkUser={checkUser} onBack={goToLogin} />}
                        {currentForm === 3 && <SendVerificationCode onResetPassword={goToResetPassword} onBack={goToLogin} />}
                        {currentForm === 4 && <ResetPassword onLogin={goToLogin} onBack={goToLogin} />}
                    </div>
                </div>
            </div>
            <div className="appRight">
                <Image
                    src="/img/ds2.webp"
                />
            </div>
        </div>
    )
}

export default LoginPage;