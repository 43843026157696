import React, { useState } from 'react';
import MultiSelect from 'multiselect-react-dropdown';
import { FaFilter } from 'react-icons/fa';
import Modal from 'react-modal';

const CustomMultiSelect = ({ options, onSelect, onRemove, selectedValues, onCloseFilters }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        onCloseFilters();
        setModalOpen(false);
    };

    return (
        <div>
            <div className='flex justify-between items-center mb-4 cursor-pointer text-lg' onClick={openModal}>
                <FaFilter />
                <span>Filtrar</span>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 max-w-md w-full rounded-lg shadow-md"
            >
                <div>
                    <h3 className="text-2xl font-semibold mb-4">Gerenciador de filtros</h3>
                    <h5 className="text-lg mb-2">Setores:</h5>
                </div>
                <div>
                    {selectedValues.length > 0 ? (
                        <MultiSelect
                            placeholder=''
                            options={options}
                            selectedValues={selectedValues}
                            onSelect={onSelect}
                            onRemove={onRemove}
                            displayValue="label"
                            emptyRecordMsg='Sem setores para filtrar'
                        />
                    ) : (
                        <MultiSelect
                            placeholder='Adicionar Setores'
                            options={options}
                            selectedValues={selectedValues}
                            onSelect={onSelect}
                            onRemove={onRemove}
                            displayValue="label"
                            emptyRecordMsg='Sem setores para filtrar'
                        />
                    )}
                </div>
                <div className="mt-4 flex justify-end">
                    <button className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 focus:outline-none" onClick={closeModal}>
                        Fechar
                    </button>
                </div>
            </Modal>

        </div>
    );
};

export default CustomMultiSelect;
